/* body * {
    margin: 10px 5px
} */

.css-k9g4rn-MuiTypography-root {
  font-size: 0.85rem !important;
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.flex-end.btn-bg-red.css-2aho86-MuiButtonBase-root-MuiButton-root {
  margin-top: 15px;
}
th.text-muted {
  display: inherit;
}
.MuiGrid-root.MuiGrid-container.css-16g5rpk-MuiGrid-root {
  margin-top: -5%;
}
.error-span.cert {
  margin-left: 2px;
  margin-top: 0px;
}
.error-span.cert.r1 {
  margin-left: 514px;
  margin-top: -4rem;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.css-1ns6j5k-MuiInputBase-root-MuiOutlinedInput-root {
  height: 46px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-grid-xs-12.css-1kuc5br-MuiGrid-root {
  margin-top: -4%;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-grid-xs-12.input-cont.css-opxrfb-MuiGrid-root {
  margin-top: -3.7%;
}
p.MuiFormHelperText-root.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained.MuiFormHelperText-filled.css-6hw8l5-MuiFormHelperText-root {
  margin-top: 20px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-grid-xs-12.input-cont.css-opxrfb-MuiGrid-root {
  margin-top: -3.5% !important;
}
.error-span.cert.d1 {
  margin-left: 4px;
  padding-bottom: 0px !important;
  width: 100px;
  padding-top: 5px;
}
h6.MuiTypography-root.MuiTypography-h6.error-span.cert.error1 {
  position: relative;
  top: 2.2rem;
  right: 0rem;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.alignment-ck-bx.checkbox1 {
  margin-top: -3.8rem !important;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.alignment-ck-bx.checkbox2 {
  margin-top: -1.2rem;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.alignment-ck-bx.checkbox3 {
  margin-top: -7.7rem;
}
.ques-styling.Additional {
  margin-top: -2rem;
}
h5.MuiTypography-root.MuiTypography-h5.ques-styling.Benefit.css-1lfcyj-MuiTypography-root {
  margin-bottom: -1.8rem;
  margin-top: 2rem;
}
h5.MuiTypography-root.MuiTypography-h5.ques-styling.Benefit.css-nkjzik {
  margin-bottom: 0.5rem;
  margin-top: 1.7rem;
}

.MuiGrid-root.MuiGrid-container.enroll.css-1v0agvb-MuiGrid-root {
  position: relative;
  bottom: 0.5rem;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-grid-xs-12.Add {
  position: relative;
  top: -40px;
  left: 256px;
  margin-top: -19px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-grid-xs-12.input-cont.Name {
  margin: -37px 0px 38px 0px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-8.spouse.css-9drkqd-MuiGrid-root {
  position: relative;
  bottom: 44px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.spouse.css-1fyyp8j-MuiGrid-root {
  position: relative;
  bottom: 29px;
}

input#outlined-adornment-amount\ max {
  padding: 11px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-6.amount.css-iol86l {
  position: relative;
  bottom: 3.7rem;
}
.spouse1.css-efwuvd {
  position: relative;
  top: 12px;
}
.spouse1.css-9drkqd-MuiGrid-root {
  position: relative;
  bottom: 25px;
}
.spouse2.css-efwuvd {
  position: relative;
  top: 32px;
}
.spouse2.css-9drkqd-MuiGrid-root {
  position: relative;
  bottom: 71px;
}
.spouse_checkbox {
  margin: 0px 0px 27px 0px;
}
.error-span.cert.r2 {
  margin-left: 31.5rem;
  margin-top: 0.5rem;
}
.Child {
  position: relative;
  bottom: 11px;
}

.MuiGrid-root.MuiGrid-container.Domestic.css-1d3bbye {
  position: relative;
  bottom: 65px;
}
@media (min-width: 2048px) {
  .MuiGrid-root.MuiGrid-container.css-16g5rpk-MuiGrid-root {
    margin-top: -2%;
  }
}

.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-4.label_input {
  position: relative;
  left: 57rem;
}
label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.label {
  position: relative;
  top: 15px;
  font-size: 13px;
}
label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.label_1 {
  font-size: 13px;
}
@media (min-width: 1200px) {
  .css-1lj5egr-MuiGrid-root {
    /* flex-basis: 23.333333% !important; */
    max-width: 33.333333%;
  }
}
.MuiFormControl-root.MuiFormControl-fullWidth.label_width {
  width: 18rem;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-4.label_input1 {
  position: relative;
  left: 56.5rem;
  bottom: 1.2rem;
}
label.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiFormLabel-root.MuiFormLabel-colorPrimary.label_2 {
  font-size: 13px;
  position: relative;
  top: -5px;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-4.label_input2 {
  position: relative;
  left: 56.5rem;
  bottom: 2.8rem;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.label_align {
  position: relative;
  bottom: 1rem;
}
h6.MuiTypography-root.MuiTypography-h6.ques-styling.select {
  margin: -23px 0px;
}
.css-1qqznl9-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: inherit;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-15j76c0.event-type {
  margin: 13px 0px 0px 0px;
}
.MuiFormControl-root.MuiFormControl-fullWidth.max-amount.css-148la44-MuiFormControl-root {
  margin: 39px 0px 0px 0px;
}
.MuiFormControl-root.MuiFormControl-fullWidth.max-amount.css-5who7u {
  margin: -2px 0px 0px;
}
.error-span.cert.options {
  width: 14rem;
  margin-top: 1rem;
}
.cancel {
  margin: 30px 0px;
}
#paraLink {
  margin: 19px 0px;
}
#para {
  margin: 4px 0px;
}
h6.MuiTypography-root.MuiTypography-h6.error-span.cert.individuals {
  position: relative;
  bottom: 3rem;
  font-weight: 400;
}
.error-span.cert.anyone {
  position: relative;
  top: -2rem;
  left: 1em;
}
.FSA-Annual.css-1trizu9-MuiFormLabel-root {
  top: 1.5rem;
  color: black !important;
  margin: 20px 0px 0px 0px; 
}
.FSA-Annual.css-1x26yiy {
  bottom: 1.4rem;
  color: black !important;
  margin: 20px 0px 0px 0px; 
}
.currency {
  margin: 0px 60px 0px 0px !important;
}
.HSA-Annual {
  display: flex;
  width: 100%;
  margin: 0px 0px 56px 0px;
}
input.currencyInput {
  padding: 13px 0px 12px 22px;
  width: 15.8rem;
  border: 1.5px solid #c6c6c6;
  border-radius: 5px;
  font-size: 1em;
}
.changeEmployeeHsaAmount {
  margin-left: 2.8rem;
}
.Dependent {
  width: 18rem;
  position: relative;
  top: 5px;
}
@media (min-width: 1200px) {
  .HSA-width {
    flex-basis: 60% !important;
    max-width: 53.5% !important;
  }
}
@media (min-width: 900px) {
  .HSA-width {
    flex-basis: 65%;
    max-width: 60%;
  }
}
@media(min-width:900px){
    .error-span.cert.HSA {
        position: relative;
        left: 3rem;
        width: 17rem;
    }
}
  @media (min-width: 1200px) {
    .error-span.cert.HSA {
        position: relative;
        top: 5px;
        left: 0px;
      }
  }