body {
  margin: 0;
 font-family: 'Poppins', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F7F5F0;
}
*{
  margin: 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', 'Poppins',
    monospace;
}

.error-span {
  display: none;
}
.error-span.show {
  display: block;
    font-size: 12px;
    color: red;
    padding-bottom: 8px;
    text-align: center;
}

.error-span.cert {
  display: block;
    font-size: 12px;
    color: red;
    padding-bottom: 8px;
}

.error-span.child {
  display: block;
    font-size: 12px;
    color: red;
    padding-bottom: 1px;
}


.error-span.doc {
  display: block;
    font-size: 12px;
    color: red;
    /* padding-bottom: 1px; */
    margin-left: 1px;;
}

.MuiFormControl-root.css-q8hpuo-MuiFormControl-root {
  margin-top: 11px;
}

/* .MuiFormControl-root.css-tzsjye {
  margin-top: 40px;
} */