/* body * {
    margin: 10px 5px
} */

.css-k9g4rn-MuiTypography-root{
    font-size: .85rem !important
}

button.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.MuiButtonBase-root.flex-end.btn-bg-red.css-2aho86-MuiButtonBase-root-MuiButton-root {
    margin-top: 15px;
}
th.text-muted {
    display: inherit;
}
/* .MuiGrid-root.MuiGrid-container.css-16g5rpk-MuiGrid-root {
    margin-top: -6.0%;
} */
.error-span.cert {
    margin-left: 12px;
}
.error-span.cert.check{
    margin-left: 30px;
}
.error-span.cert.r1 {
    margin-left: 480px;
}
.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.MuiInputBase-adornedStart.css-1ns6j5k-MuiInputBase-root-MuiOutlinedInput-root {
    height: 46px;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-grid-xs-12.css-1kuc5br-MuiGrid-root {
    margin-top: -4.0%;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-grid-xs-12.input-cont.css-opxrfb-MuiGrid-root {
    margin-top: -3.7%;
}
p.MuiFormHelperText-root.MuiFormHelperText-sizeMedium.MuiFormHelperText-contained.MuiFormHelperText-filled.css-6hw8l5-MuiFormHelperText-root {
    margin-top: 20px;
}
.error-span.cert.d1 {
    margin-left: 4px;
}

.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.MuiGrid-grid-xs-12.input-cont.css-opxrfb-MuiGrid-root {
    margin-top: -4.5% !important;
}
.error-span.cert.d1 {
    margin-left: 4px;
    padding-bottom: 0px !important;
}

.MuiFormControl-root.MuiFormControl-fullWidth.input_amount.css-dms7x9{
    position: relative;
    bottom: 4rem;
}
/* .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.css-bu7jlb-MuiInputBase-root-MuiOutlinedInput-root {
    width: 70vh;
} */
.MuiFormControl-root.MuiTextField-root.css-i44wyl {
    margin-top: -30px;
}


.css-6gicuz {
    margin: 29px 14px 0px !important;
    position: relative;
    bottom: 0.5rem;
}

p.MuiTypography-root.MuiTypography-body1.input-name.Enroll {
    margin: 0.5rem 0rem;
}
.css-fczk9k-MuiFormHelperText-root {
    margin: 29px 14px 0px !important;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.MuiGrid-grid-xs-12.plan {
    margin-top: -10px;
}
h5.MuiTypography-root.MuiTypography-h5.ques-styling.change{
    margin: -13px 0px;
}
.error-span.cert.enroll {
    margin: -4rem 0rem 0rem 31rem;
}
.amount{
    margin: -1rem 0rem 0rem 2rem !important; 
}
.commenterName{
    width: 24rem;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.alignment-ck-bx.checkbox1 {
    margin-top: -3.8rem !important; 
  }
  span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.alignment-ck-bx.checkbox2 {
    margin-top: -1.2rem;
  }
  span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.alignment-ck-bx.checkbox3 {
    margin-top: -7.7rem;
  }
  
.error-span.cert.anyone1 {
    position: relative;
    top: -0.5rem;
  }

  input.currencyInput1 {
    padding: 13px 0px 12px 22px;
    width: 15.8rem;
    border: 1.5px solid #c6c6c6;
    border-radius: 5px;
    font-size: 1em;
  }
  @media only screen and (max-width: 768px) {

    input.currencyInput1{
        position: relative;
        top: 4rem;
        left: 0.5rem;
    }
}
